import React, { useEffect, useState } from "react"
import logo from "../../../images/icon.png"
import "./Header.css"

export default function Header() {
  const goToSevenClip = () => {
    window.location.href = "https://app.sevenclip.com"
  }

  return (
    <header>
      <a className="home" href="#header">
        <img alt="logo" src={logo} />
        <p>SevenClip</p>
      </a>

      <div className="linkDiv">
        <div className="subLinkDiv">
          <a href="#main">Home</a>
          <a href="#features">Features</a>
          <a href="#pricing">Pricing</a>
        </div>
        <button
          onClick={goToSevenClip}
          className="primary"
          style={{ fontSize: 17 }}
        >
          Login
        </button>
      </div>
    </header>
  )
}
