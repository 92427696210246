import React, { CSSProperties } from "react"

interface PropShape {
  icon: React.ReactElement
  title: string
  text: string
  openComponent?: React.ReactNode
  style?: CSSProperties
}

export default function GridBox(props: PropShape) {
  const renderedStyles = {
    cursor: props.openComponent ? "pointer" : "default",
  }

  const clickedDiv = () => {
    window.location.href = "/itemopener"
  }

  return (
    <div
      onClick={clickedDiv}
      style={{ ...props.style, ...renderedStyles }}
      className="gridBox"
    >
      {props.icon}
      <h4>{props.title}</h4>
      <p>{props.text}</p>
    </div>
  )
}
