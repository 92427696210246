import React from "react"
import "./Section4.css"
import { IoLogoInstagram, IoLogoTwitter } from "react-icons/io"
import { IoLogoFacebook } from "react-icons/io5";
import yaml from "js-yaml";

interface linkData {
  name: string
  data: string
  processer: number
  id: number
}

const links: linkData[] = [
  {
    name: "Windows",
    data: "win",
    processer: 0,
    id: 0,
  },
  {
    name: "macOS",
    data: "mac",
    processer: 0,
    id: 1,
  },
  {
    name: "Linux",
    data: "linux",
    processer: 0,
    id: 2,
  },
  {
    name: "Web",
    data: "https://app.sevenclip.com",
    processer: 1,
    id: 3,
  },
]

export default function Section4() {
  const processLink = async (link: linkData) => {
    if (link.processer === 0) {
      const fileUrls: any = await getFiles()
      const url = fileUrls[link.data]
      downloadURL(url)
    }

    if (link.processer === 1) {
      window.location.href = link.data
    }
  }

  const getFiles = () => {
    return new Promise(async (resolve, reject) => {
      const baseUrl = "https://sevenclipapp.s3.eu-north-1.amazonaws.com/"

      const files = {
        win: "latest.yml",
        mac: "latest-mac.yml",
        linux: "latest-linux.yml",
      }
      let fileNames: any = {}

      for (const [key, value] of Object.entries(files)) {
        const response = await fetch(baseUrl + value).catch(error =>
          console.log(`Failed because: ${error}`)
        )

        if (response) {
          const data = await response.text()
          var obj: any = yaml.safeLoad(data)
          if (obj && obj.files && obj.files.length > 0) {
            // Getting the last object because of some Mac trouble in Electron-Builder
            fileNames[key] = baseUrl + obj.files[obj.files.length - 1].url
          }
        }
      }

      resolve(fileNames)
    })
  }

  const downloadURL = (url: string) => {
    let iframe = document.createElement("iframe")
    iframe.style.display = "none"
    document.body.appendChild(iframe)
    iframe.src = url
  }

  return (
    <footer>
      <div className="content">
        <div className="box social">
          <p>Be social with us</p>
          <a
            target="_blank"
            href="https://www.facebook.com/SevenClip-106576697930614"
            rel="noopener noreferrer"
          >
            <IoLogoFacebook style={{ color: "#3479F0" }} />
          </a>

          <a
            target="_blank"
            href="https://instagram.com/sevenclip_"
            rel="noopener noreferrer"
          >
            <IoLogoInstagram style={{ color: "#CC3684" }} />
          </a>

          <a
            target="_blank"
            href="https://twitter.com/sevenclip"
            rel="noopener noreferrer"
          >
            <IoLogoTwitter style={{ color: "#3FA2F3" }} />
          </a>
        </div>

        <div className="grid">
          <div className="box">
            <p>Links</p>
            <a href="/privacypolicy">Privacy policy</a>
            <a href="/termsofservice">Terms of Service</a>
            <a href="/cookiepolicy">Cookie policy</a>
          </div>

          <div className="box">
            <p>Features</p>
            <a href="#section2">Save passwords</a>
            <a href="#section2">Manage notes</a>
            <a href="#section2">Cloud based</a>
            <a href="#section2">Manage bookmarks</a>
            <a href="#section2">Items opener</a>
            <a href="#section2">Millitary-Grade Encryption</a>
          </div>

          <div className="box">
            <p>available on</p>

            {links.map(link => (
              <a
                key={link.id}
                target="_blank"
                onClick={e => {
                  e.preventDefault()
                  processLink(link)
                }}
                href=""
                rel="noopener noreferrer"
              >
                {link.name}
              </a>
            ))}
          </div>

          <div className="box">
            <p>Contact</p>
            <a href="mailto:support@sevenclip.com" rel="noopener noreferrer">
              support@sevenclip.com
            </a>
            <a href="https://benjam.tech" rel="noopener noreferrer">
              Benjam.Tech (CEO)
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}
