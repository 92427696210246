import React from "react"
import "./Section3.css"
import logo from "../../../images/icon.png"

interface PropShape {
  name: string
  text: string | string[]
  price: number
  primary?: boolean
}

function Packages(props: PropShape) {
  const signup = () => {
    window.location.href = "https://app.sevenclip.com/?signup"
  }

  return (
    <div className={`plans ${props.primary ? "primary" : ""}`}>
      <img src={logo} alt="logo" />
      <h2>{props.name}</h2>

      <div className="textList">
        {Array.isArray(props.text) ? (
          props.text.map(text => <p key={text} className="text">{text}</p>)
        ) : (
          <p className="text">{props.text}</p>
        )}
      </div>
      <div className="price">
        <p className="dollar">$</p>
        <p className="price">{props.price}</p>
        <p className="period">
          per
          <br />
          month
        </p>
      </div>

      <button
        onClick={signup}
        className={props.primary ? "primary" : "secondary"}
      >
        Sign up now
      </button>
    </div>
  )
}

export default function Section3() {
  return (
    <div className="Section3" id="pricing">
      <Packages
        name="free"
        text={["Unlimited folders", "Unlimited items", "Smart insert with quickWindow (desktop only)"]}
        price={0}
      />
      <Packages
        name="pro"
        text={["All in free +", "Share folders with up to 3 participants"]}
        price={4.99}
        primary
      />
      <Packages
        name="business"
        text={[
          "All in free +",
          "Share folders with up to 70 people",
          "User permissions",
        ]}
        price={49.99}
      />
    </div>
  )
}
