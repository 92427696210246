import React from "react"
import Helmet from "react-helmet"
import "./AllPage.css"
import Header from "./Header/Header"

import Section1 from "./Section1/Section1"
import Section2 from "./Section2/Section2"
import Section3 from "./Section3/Section3"
import Section4 from "./Section4/Section4"

export default () => {
  return (
    <div className="AllPage">
      <Helmet>
        <title>SevenClip</title>
        <meta
          name="title"
          content="SevenClip - Taking an innovative step ahead"
        />
        <meta
          name="description"
          content="Taking an innovative step ahead with SevenClip, the simple solution to store your notes / passwords / texts / bookmarks / code and groups"
        />
        <meta
          name="keywords"
          content="password manager, bookmark manager, note taking, code snippets, information storage"
        />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="10 days" />
        <meta name="author" content="Benjam.Tech" />
      </Helmet>

      <Header />
      <div className="sec sec1">
        <div className="virSec" />
        <div className="middle">
          <Section1 />
        </div>
      </div>

      <div className="sec sec2">
        <div className="middle" style={{ transform: "skewY(8deg)" }}>
          <Section2 />
        </div>
      </div>

      <div className="sec sec3">
        <div className="middle">
          <Section3 />
        </div>
      </div>

      <Section4 />
    </div>
  )
}
