import React from "react"
import "./Section1.css"
//import video from "../../../videoes/video.mp4"
import mainimg from "../../../images/mainimg.svg"

export default () => {
  const goToSevenClip = () => {
    window.location.href = "https://app.sevenclip.com"
  }

  return (
    <div className="Section1" id="#main">
      <div className="left">
        <h1>Taking an innovative step ahead</h1>

        <h2>
          With SevenClip, the simple solution to store your notes / passwords /
          texts / bookmarks / code and groups
        </h2>

        <button onClick={goToSevenClip} className="primary">
          Start for Free
        </button>
      </div>

      {/*
        <video autoPlay muted>
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
*/}
      <img src={mainimg} alt="main" />
    </div>
  )
}
