import React, { useState } from "react"
import AllPage from "../components/newSite/AllPage"
import "../globalStyles/index.css"
import "../globalStyles/variables.css"

export default function Home() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <AllPage />
    </>
  )
}
