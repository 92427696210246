import React from "react"
import {
  IoIosBookmark,
  IoIosCloud,
  IoIosCreate,
  IoIosList,
  IoIosLock,
  IoIosLogIn,
  IoMdBookmark,
  IoMdCloud,
  IoMdKey,
  IoMdLock,
  IoMdSearch,
  IoMdShare,
} from "react-icons/io"
import GridBox from "./GridBox"
import "./Section2.css"

export default function Section2() {
  return (
    <div className="Section2" id="features">
      <h3>All-In-One</h3>
      <hr />
      <p
        className="info"
        style={{
          fontSize: "var(--font-size-500)",
          color: "var(--text-200)",
          marginTop: 7,
          maxWidth: "100%",
          textAlign: "center",
        }}
      >
        SevenClip is a digital storage solution that keeps your data safe. Focus on your daily work and enjoy the use of SevenClip
      </p>

      <div className="grid">
        <GridBox
          icon={<IoMdKey style={{ color: "#099AA4" }} />}
          title="Save passwords"
          text="Simply save passwords and pick them up when needed"
        />
        <GridBox
          icon={<IoMdSearch style={{ color: "#CF1124" }} />}
          title="Items opener"
          text="The golden feature of SevenClip"
          style={{ border: "3px solid #ffd700" }}
          openComponent={<p>Hei du</p>}
        />

        <GridBox
          icon={<IoIosCreate style={{ color: "#EF8E58" }} />}
          title="Manage notes"
          text="Write down notes, texts, random thoughts, and keep them all in one place."
        />

        <GridBox
          icon={<IoMdCloud style={{ color: "#7BC47F" }} />}
          title="Cloud-based"
          text="Keep content online and freely access it from anywhere and anytime"
        />
        <GridBox
          icon={<IoMdBookmark style={{ color: "#F368FC" }} />}
          title="Manage bookmarks"
          text="Simply copy and paste multiple URLs and then open them with one click"
        />
        <GridBox
          icon={<IoMdLock style={{ color: "#14919B" }} />}
          title="Military-Grade Encryption"
          text="Your personal data is protected with multiple of the strongest encryption standards that are commercially available today"
        />
      </div>
    </div>
  )
}
